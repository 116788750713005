export default [
  {
    title: "Определение расчётного сопротивления грунта",
    href: "/r-definition",
    img: "/img/rDef.png"
  },
  {
    title: "Расчёт листового настила",
    href: "./decking/decking.htm",
    img: "/img/decking.png"
  },
  {
    title: "Расчёт строповочных петель",
    href: "./loops/loops.htm",
    img: "/img/loop.png"
  },
  {
    title: "Расчёт косоура, перил, расчёт на зыбкость",
    href: "/mladder/mladder.htm",
    img: "/img/mladder.jpg"
  },
  {
    title: "Расчёт анкерных болтов",
    href: "/anchor2017/anchor.htm",
    img: "/img/anchor.png"
  },
  {
    title: "Расчёт на продавливание",
    href: "/punching2017/punching.htm",
    img: "/img/pun.jpg"
  },
  {
    title: "Графический метод определения давления грунта",
    href: "/muro2017/index.html",
    img: "/img/muro.png"
  },
  {
    title: "Расчёт свайных фундаментов СП24",
    href: "/pile2017/piles.htm",
    img: "/img/pile.png"
  },
  {
    title: "Расчёт упоров",
    href: "/catcher2018/index.htm",
    img: "/img/cathcer.png"
  },
  {
    title: "Расчёт фундаментов",
    href: "https://meduzapro.com/",
    img: "/img/foundations.png"
  }
];
