// 2020.06.27 12:40 -
import React, { useState, useEffect } from "react";
import "./util/draft.styles.css";
import classes from "./r-definition.module.css";

import {
  defaultWell,
  defaultLevels,
  defaultRest,
  defaultBasement,
  defaultSchema
} from "./util/default-values";
import renderDraft from "./util/draft-render";
import { getR } from "./back/func";

import SoilTable from "./components/soil-table/soil-table.component";
import DataTable from "./components/data-table/data-table.component";
import Report from "./components/report/report.component";

// TODO:
// Промежуточная подготовка
// Прерывистые фундаменты
// пояснению с таблицами к расчёту
// тесты на каждый случай для самого расчёта
// тесты для UI
// адекватное масштабирование чертежа
// приближение колесом мышки
// перемещение чертежа с зажатой правой кнопкой мышки
// попробовать переделать графику по принципу чертежа
// catchError
// checkbox to component
// координаты пола подвала по умолчанию
// пол подвала не должен заходить на подошву фундамента

// ошибка 404 firebase

/*const hardWell = [
  {
    type: "made",
    structure: "",
    moisture: "",
    il: "",
    phi2: "",
    c2: "",
    gamma2: 1.8,
    thickness: 1.5
  },
  {
    type: "sand",
    structure: "dust",
    moisture: "wet",
    il: "",
    phi2: "28",
    c2: "0.1",
    gamma2: 1.65,
    thickness: 1
  },
  {
    type: "clay",
    structure: "",
    moisture: "",
    il: "0.25",
    phi2: "21",
    c2: "1.1",
    gamma2: 1.7,
    thickness: 1.5
  }
];

const hardRest = { b: 1.8, hp: "" };

const hardSchema = { lSize: 40, hSize: 50 };

const hardLevels = { nl: 0.0, dl: -0.1, cl: 0.1, fl: -1.7 };

const hardBasement = { hs: 0.5, hcf: 0.15, gammacf: 2.1 };*/

const RDefinition = props => {
  const [well, setWell] = useState([defaultWell]);
  const [basement, setBasement] = useState(false);
  const [k, setK] = useState(false);
  const [schema, setSchema] = useState(false);
  const [preparation, setPreparation] = useState(false);
  const [state, setState] = useState({
    /*...hardLevels*/ ...defaultLevels,
    /*...hardRest */ ...defaultRest,
    /*...hardBasement*/ ...defaultBasement,
    /*...hardSchema */ ...defaultSchema
  });
  const [errors, setErrors] = useState({});
  const [report, setReport] = useState(null);
  const [isDone, setDone] = useState(false);

  useEffect(() => {
    renderDraft(
      {
        levels: { nl: state.nl, dl: state.dl, cl: state.cl, fl: state.fl },
        well,
        report,
        basement: { hs: state.hs, hcf: state.hcf, check: basement }
      },
      "draft"
    );
  });

  useEffect(() => {
    if (well || basement || k || schema || preparation || state) {
      setDone(false);
      setReport(null);
    }
  }, [well, basement, k, schema, preparation, state]);

  const handleChangeWell = (e, index, key) => {
    const value = e.target.value;
    setWell(state =>
      state.map((layer, i) =>
        index === i ? { ...layer, [key]: value } : layer
      )
    );
    if (errors.well && errors.well[index][key]) {
      const newWellErrors = { ...errors.well };
      delete newWellErrors[index][key];
      setErrors({ ...errors, well: newWellErrors });
    }
  };

  const handleChangeState = e => {
    const key = e.target.name;
    const value = e.target.value;
    const newState = { ...state, [key]: value };
    setState(newState);
    if (key in errors) {
      const newErrors = { ...errors };
      delete newErrors[key];
      setErrors(newErrors);
    }
  };

  const handleAddSoil = () => {
    setWell([...well, defaultWell]);
    if (errors.well) setErrors({ errors, well: [...errors.well, {}] });
  };

  const handleRemoveSoil = index => {
    const newWell = well.filter((_, i) => i !== index);
    setWell(newWell);
  };

  const isCorrect = () => {
    const wellErrors = [];
    const stateErrors = {};

    for (let i = 0; i < well.length; i++) {
      wellErrors.push({});
      if (well[i].type === "made") {
        if (!well[i].gamma2) wellErrors[i].gamma2 = true;
        if (!well[i].thickness) wellErrors[i].thickness = true;
        continue;
      } else if (well[i].type === "sand") {
        if (!well[i].structure) wellErrors[i].structure = true;
        if (well[i].structure === "dust" && !well[i].moisture)
          wellErrors[i].moisture = true;
      } else if (well[i].type === "clay") {
        if (!well[i].il) wellErrors[i].il = true;
      } else {
        wellErrors[i].type = true;
        continue;
      }

      if (!well[i].phi2) wellErrors[i].phi2 = true;
      if (!well[i].c2) wellErrors[i].c2 = true;
      if (!well[i].gamma2) wellErrors[i].gamma2 = true;
      if (!well[i].thickness) wellErrors[i].thickness = true;
    }

    Object.keys(state).forEach(key => {
      if (key === "hs" || key === "hcf" || key === "gammacf") {
        if (basement) {
          if (!state[key]) stateErrors[key] = true;
        }
      } else if (key === "lSize" || key === "hSize") {
        if (schema) {
          if (!state[key]) stateErrors[key] = true;
        }
      } else if (key === "dl" || key === "nl" || key === "cl") {
        if (!state[key] && state[key] !== 0) stateErrors[key] = true;
      } else if (key === "hp") {
        if (preparation) {
          if (!state[key]) stateErrors[key] = true;
        }
      } else {
        if (!state[key]) stateErrors[key] = true;
      }
    });

    setErrors({ well: wellErrors, ...stateErrors });

    for (let layer of wellErrors) {
      if (Object.keys(layer).length !== 0) return false;
    }
    if (Object.keys(stateErrors).length !== 0) return false;

    return true;
  };

  const handleSubmit = () => {
    if (isCorrect()) {
      const rep = getR({
        ...state,
        well,
        k,
        basement,
        schema,
        preparation
      });
      setReport(rep);
      setDone(true);
    } else {
      setReport("Ошибка в исходных данных");
    }
  };

  return (
    <div>
      <h3 className={classes.pageTitle}>
        Определение расчётного сопротивления грунта основания (СП 22.13330.2016
        с Изменениями № 1, 2, 3)
      </h3>
      <div>
        <div className={classes.soilTable}>
          <div className={classes.subTitle}>Характеристики грунтов</div>
          <SoilTable
            well={well}
            errors={errors}
            handleChange={handleChangeWell}
            handleRemove={handleRemoveSoil}
          />
          <button className={classes.addButton} onClick={handleAddSoil}>
            Добавить слой
          </button>
          <div>
            <input
              name="k"
              id="k"
              className={classes.checkBox}
              type="checkbox"
              value={k}
              onChange={() => setK(!k)}
            />
            <label htmlFor="k">
              значения характеристик грунтов взяты из таблицы СП
            </label>
          </div>
        </div>
        <div className={classes.mainView}>
          <div className={classes.input}>
            <div className={classes.subTitle}>Ширина подошвы</div>
            <DataTable
              data={{ b: state.b }}
              errors={errors}
              handleChange={handleChangeState}
            />
            <div className={classes.subTitle}>Отметки</div>
            <DataTable
              data={{ dl: state.dl, nl: state.nl, cl: state.cl, fl: state.fl }}
              errors={errors}
              handleChange={handleChangeState}
            />
            <div className={classes.subTitleWithCheckbox}>
              <input
                name="basement"
                id="basement"
                className={classes.checkBox}
                type="checkbox"
                value={basement}
                onChange={() => setBasement(!basement)}
              />
              <label htmlFor="basement">Подвал</label>
            </div>
            <div className={basement ? classes.divShow : classes.divHide}>
              <DataTable
                data={{
                  hs: state.hs,
                  hcf: state.hcf,
                  gammacf: state.gammacf
                }}
                errors={errors}
                handleChange={handleChangeState}
              />
            </div>
            <div className={classes.subTitleWithCheckbox}>
              <input
                name="schema"
                id="schema"
                className={classes.checkBox}
                type="checkbox"
                value={schema}
                onChange={() => setSchema(!schema)}
              />
              <label htmlFor="schema">Жесткая схема здания</label>
            </div>
            <div className={schema ? classes.divShow : classes.divHide}>
              <DataTable
                data={{
                  lSize: state.lSize,
                  hSize: state.hSize
                }}
                errors={errors}
                handleChange={handleChangeState}
              />
            </div>
            <div className={classes.subTitleWithCheckbox}>
              <input
                name="preparation"
                id="preparation"
                className={classes.checkBox}
                type="checkbox"
                value={preparation}
                onChange={() => setPreparation(!preparation)}
              />
              <label htmlFor="preparation">Бет/щеб подготовка</label>
              <div className={preparation ? classes.divShow : classes.divHide}>
                <DataTable
                  data={{
                    hp: state.hp
                  }}
                  errors={errors}
                  handleChange={handleChangeState}
                />
              </div>
            </div>
          </div>
          <div className={classes.draw}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="draft"
              width="450"
              height="450"
              viewBox="0 0 450 450"
            >
              Ваш браузер не поддерживает svg
            </svg>
          </div>
        </div>
      </div>
      <button
        className={classes.submitButton}
        onClick={handleSubmit}
        disabled={isDone}
      >
        РАСЧЁТ
      </button>
      <div className={classes.pageBreak}></div>
      {report && <Report report={report} />}
    </div>
  );
};

export default RDefinition;
