export const defaultWell = {
  type: "",
  structure: "",
  moisture: "",
  il: "",
  phi2: "",
  c2: "",
  gamma2: "",
  thickness: ""
};
export const defaultLevels = { nl: "", dl: "", cl: "", fl: "" };
export const defaultRest = { b: "", hp: "" };
export const defaultBasement = { hs: "", hcf: "", gammacf: "" };
export const defaultSchema = { lSize: "", hSize: "" };
