import React, { Fragment } from "react";
import classes from "./data-table.module.css";

const DataTable = ({ data, errors, handleChange }) => {
  const keys = Object.keys(data);
  return (
    <table className={classes.dataTable}>
      <tbody>
        {keys.map(key => (
          <tr key={key}>
            <td>{details[key].name}</td>
            <td>
              <input
                className={key in errors ? classes.inputError : null}
                autoComplete="off"
                name={key}
                type="number"
                value={data[key]}
                onChange={handleChange}
              />
            </td>
            <td>{details[key].units}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default DataTable;

const details = {
  phi: {
    name: (
      <Fragment>
        &phi;<sub>II</sub> =
      </Fragment>
    ),
    units: <Fragment>&deg;</Fragment>
  },
  c: {
    name: (
      <Fragment>
        c<sub>II</sub> =
      </Fragment>
    ),
    units: (
      <Fragment>
        т/м<sup>2</sup>
      </Fragment>
    )
  },
  b: {
    name: "b =",
    units: "м"
  },
  l: {
    name: "l =",
    units: "м"
  },
  dl: {
    name: "DL =",
    units: "м"
  },
  nl: {
    name: "NL =",
    units: "м"
  },
  cl: {
    name: "CL =",
    units: "м"
  },
  fl: {
    name: "FL =",
    units: "м"
  },
  hs: {
    name: (
      <Fragment>
        h<sub>s</sub> =
      </Fragment>
    ),
    units: "м"
  },
  hcf: {
    name: (
      <Fragment>
        h<sub>cf</sub> =
      </Fragment>
    ),
    units: "м"
  },
  gammacf: {
    name: (
      <Fragment>
        &gamma;<sub>cf</sub> =
      </Fragment>
    ),
    units: (
      <Fragment>
        т/м<sup>3</sup>
      </Fragment>
    )
  },
  lSize: {
    name: "L =",
    units: "м"
  },
  hSize: {
    name: "H =",
    units: "м"
  },
  hp: {
    name: (
      <Fragment>
        h<sub>p</sub> =
      </Fragment>
    ),
    units: "м"
  }
};
