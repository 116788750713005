export const table54 = {
  same: [1.4, 1.2, 1.4],
  small: [1.3, 1.1, 1.3],
  dust: [1.25, 1.0, 1.2],
  semiwet: [1.25, 1.0, 1.2], // copy of "dust" because I need identify semiwet sand for good appearence in table front SoilsTable:124
  wet: [1.1, 1.0, 1.2],
  clay25: [1.25, 1.0, 1.1],
  clay50: [1.2, 1.0, 1.1],
  clay100: [1.1, 1.0, 1.0]
}
export const table55 = [
    0,	0.00,	1.00,	3.14, 1,	0.01,	1.06,	3.23, 2,	0.03,	1.12,	3.32,
    3,	0.04,	1.18,	3.41, 4,	0.06,	1.25,	3.51, 5,	0.08,	1.32,	3.61,
    6,	0.10,	1.39,	3.71, 7,	0.12,	1.47,	3.82, 8,	0.14,	1.55,	3.93,
    9,	0.16,	1.64,	4.05, 10,	0.18,	1.73,	4.17, 11,	0.21,	1.83,	4.29,
    12,	0.23,	1.94,	4.42, 13,	0.26,	2.05,	4.55, 14,	0.29,	2.17,	4.69,
    15,	0.32,	2.30,	4.84, 16,	0.36,	2.43,	4.99, 17,	0.39,	2.57,	5.15,
    18,	0.43,	2.73,	5.31, 19,	0.47,	2.89,	5.48, 20,	0.51,	3.06,	5.66,
    21,	0.56,	3.24,	5.84, 22,	0.61,	3.44,	6.04, 23,	0.66,	3.65,	6.24,
    24,	0.72,	3.87,	6.45, 25,	0.78,	4.11,	6.67, 26,	0.84,	4.37,	6.90,
    27,	0.91,	4.64,	7.14, 28,	0.98,	4.93,	7.40, 29,	1.06,	5.25,	7.67,
    30,	1.15,	5.59,	7.95, 31,	1.24,	5.95,	8.24, 32,	1.34,	6.34,	8.55,
    33,	1.44,	6.76,	8.88, 34,	1.55,	7.22,	9.22, 35,	1.68,	7.71,	9.58,
    36,	1.81,	8.24,	9.97, 37,	1.95,	8.81,	10.37, 38,	2.11,	9.44,	10.80,
    39,	2.28,	10.11,	11.25, 40,	2.46,	10.85,	11.73, 41,	2.66,	11.64,	12.24,
    42,	2.88,	12.51,	12.79, 43,	3.12,	13.46,	13.37, 44,	3.38,	14.50,	13.98,
    45,	3.66,	15.64,	14.64
  ]
