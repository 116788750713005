import React, { Fragment } from "react";
import classes from "./soil-table.module.css";

const tableHeader = [
  "",
  "#",
  "Тип",
  "Крупность",
  "Влажность",
  <Fragment>
    I<sub>l</sub>
  </Fragment>,
  <Fragment>
    &phi;<sub>II</sub>, &deg;
  </Fragment>,
  <Fragment>
    c<sub>II</sub>, т/м<sup>2</sup>
  </Fragment>,
  <Fragment>
    &gamma;<sub>II</sub>, т/м<sup>3</sup>
  </Fragment>,
  "h, м"
];

const SoilTable = ({ well, errors, handleChange, handleRemove }) => {
  return (
    <table className={classes.table}>
      <thead>
        <tr>
          {tableHeader.map((item, index) => (
            <th key={"head" + index}>{item}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {well.map((row, index) => (
          <tr key={"layer" + index}>
            <td>
              <button
                onClick={() => handleRemove(index)}
                disabled={well.length < 2}
              >
                -
              </button>
            </td>
            <td>{index + 1}</td>
            {Object.keys(row).map(key => (
              <td key={"soil" + key + index}>
                {(() => {
                  switch (key) {
                    case "type":
                      return (
                        <select
                          className={
                            errors.well && errors.well[index][key]
                              ? classes.inputError
                              : null
                          }
                          name={key}
                          value={row[key]}
                          onChange={e => handleChange(e, index, key)}
                        >
                          <option value="" disabled></option>
                          <option value="made">Насыпной</option>
                          <option value="sand">Песок</option>
                          <option value="clay">Глина</option>
                        </select>
                      );
                    case "structure":
                      return row["type"] === "sand" ? (
                        <select
                          className={
                            errors.well && errors.well[index][key]
                              ? classes.inputError
                              : null
                          }
                          name={key}
                          value={row[key]}
                          onChange={e => handleChange(e, index, key)}
                        >
                          <option value="" disabled></option>
                          <option value="same">крупнообломочные</option>
                          <option value="small">мелкие</option>
                          <option value="dust">пылеватые</option>
                          <option value="loose">рыхлые</option>
                        </select>
                      ) : null;
                    case "moisture":
                      return row["structure"] === "dust" ? (
                        <select
                          className={
                            errors.well && errors.well[index][key]
                              ? classes.inputError
                              : null
                          }
                          name={key}
                          value={row[key]}
                          onChange={e => handleChange(e, index, key)}
                        >
                          <option value="" disabled></option>
                          <option value="semiwet">маловлажные</option>
                          <option value="wet">насыщенные</option>
                        </select>
                      ) : null;
                    case "il":
                      return row["type"] === "clay" ? (
                        <input
                          className={
                            errors.well && errors.well[index][key]
                              ? classes.inputError
                              : null
                          }
                          type="number"
                          value={row[key]}
                          onChange={e => handleChange(e, index, key)}
                        />
                      ) : null;
                    case "phi2":
                      return row["type"] !== "made" ? (
                        <input
                          className={
                            errors.well && errors.well[index][key]
                              ? classes.inputError
                              : null
                          }
                          type="number"
                          value={row[key]}
                          onChange={e => handleChange(e, index, key)}
                        />
                      ) : null;
                    case "c2":
                      return row["type"] !== "made" ? (
                        <input
                          className={
                            errors.well && errors.well[index][key]
                              ? classes.inputError
                              : null
                          }
                          type="number"
                          value={row[key]}
                          onChange={e => handleChange(e, index, key)}
                        />
                      ) : null;
                    default:
                      return (
                        <input
                          className={
                            errors.well && errors.well[index][key]
                              ? classes.inputError
                              : null
                          }
                          type="number"
                          value={row[key]}
                          onChange={e => handleChange(e, index, key)}
                        />
                      );
                  }
                })()}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SoilTable;
