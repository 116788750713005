import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import "./App.css";

import Header from "./components/header/header.component";
import Footer from "./components/footer/footer.component";

import home from "./pages/home/home.page";
import rDefinition from "./pages/foundation/r-definition/r-definition.page";

// TODO:
// refact fouter like crw clother
// catch error 404

const App = () => {
  return (
    <div className="App">
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" component={home} />
          <Route path="/index.htm" component={home} />
          <Route path="/r-definition" component={rDefinition} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
