import React from "react";
import classes from "./home.module.css";

import pagesList from "../../util/pagesList";

import MenuItem from "../../components/menu-item/menu-item.component";

// TODO:
// all routs should be here?
// change href to push to history

const Home = props => {
  return (
    <main className={classes.main}>
      {pagesList.map(page => (
        <MenuItem key={page.href} {...page} />
      ))}
    </main>
  );
};

export default Home;
