// func
import { table54, table55 } from "./spData";

const check = task => {
  let message = "";

  const min = Math.min(task.dl, task.nl, task.cl, task.fl);
  if (min !== task.fl) message += "Неверно задан уровень подошвы FL\n";

  const soilHight = task.well.reduce(
    (total, current) => total + current.thickness,
    0
  );
  if (-soilHight > task.fl - task.nl) message += "Геологии недостаточно";

  return message;
};

export const getR = data => {
  const task = toNumber(data);

  const checkResult = check(task);
  if (checkResult) return checkResult;

  const soilUnder = getSoilUnder(task);
  if (data.well[soilUnder].type === "made")
    return "Насыпной грунт не может служить в качестве основания";
  const k = getK(task);
  const gamma2s = getGamma2s(task);
  const gammac = getGammaC1GammaC2(task, soilUnder);
  const d1db = getD1Db(task, gamma2s);
  const kz = getKz(task.b);
  const gamma2 = getGamma2(task, soilUnder);
  const mmm = getMMM(task, soilUnder, gamma2.phi2);
  const r =
    ((gammac.c1 * gammac.c2) / k.k) *
    (mmm.Mg * kz.kz * task.b * gamma2.gamma2 +
      mmm.Mq * d1db.d1 * gamma2s +
      (mmm.Mq - 1) * d1db.db * gamma2s +
      mmm.Mc * gamma2.c2);

  return {
    data: task,
    soilUnder,
    k,
    gamma2s,
    gammac,
    d1db,
    kz,
    gamma2,
    mmm,
    r
  };
};

const getMMM = (task, soilUnder, _phi2) => {
  const data = table55;
  const index = data.indexOf(Math.round(_phi2 || task.well[soilUnder].phi2));
  const m = {
    Mg: data[index + 1],
    Mq: data[index + 2],
    Mc: data[index + 3]
  };
  return m;
};

const getGamma2 = (task, soilUnder) => {
  const z1 = 4;
  const d = Math.abs(task.fl - task.nl);
  const { b } = task;
  let z,
    text = "",
    gamma2 = 0,
    phi2 = 0,
    c2 = 0,
    d1 = 0,
    average = false;

  if (!b) {
    return {
      gamma2: task.well[soilUnder].gamma2,
      phi2: task.well[soilUnder].phi2,
      c2: task.well[soilUnder].c2,
      gamma2Text: text
    };
  } else if (b < 10) {
    z = b / 2;
  } else {
    z = z1 + 0.1 * b;
  }

  for (let i = 0; i < task.well.length; i++) {
    d1 += task.well[i].thickness;

    if (d1 > d) {
      if (z > d1 - d) {
        gamma2 +=
          gamma2 === 0
            ? (d1 - d) * task.well[i].gamma2
            : task.well[i].thickness * task.well[i].gamma2;
        phi2 +=
          phi2 === 0
            ? (d1 - d) * task.well[i].phi2
            : task.well[i].thickness * task.well[i].phi2;
        c2 +=
          c2 === 0
            ? (d1 - d) * task.well[i].c2
            : task.well[i].thickness * task.well[i].c2;
        average = true;
      } else {
        if (gamma2 !== 0) average = true;
        gamma2 +=
          gamma2 === 0
            ? task.well[i].gamma2 * z
            : (task.well[i].thickness - (d1 - (d + z))) * task.well[i].gamma2;
        phi2 +=
          phi2 === 0
            ? task.well[i].phi2 * z
            : (task.well[i].thickness - (d1 - (d + z))) * task.well[i].phi2;
        c2 +=
          c2 === 0
            ? task.well[i].c2 * z
            : (task.well[i].thickness - (d1 - (d + z))) * task.well[i].c2;
        break;
      }
    }
  }

  if (z > d1 - d) {
    gamma2 += task.well[task.well.length - 1].gamma2 * (z - (d1 - d));
    phi2 += task.well[task.well.length - 1].phi2 * (z - (d1 - d));
    c2 += task.well[task.well.length - 1].c2 * (z - (d1 - d));
    text = `Геология недостаточна. Необходимо минимум ${z.toFixed(
      2
    )} м грунта ниже уровня подошвы. `;
    text += `Значение характеристик приняты по последнему слою.`;
  }
  return {
    gamma2: gamma2 / z,
    phi2: phi2 / z,
    c2: c2 / z,
    gamma2Text: text,
    z: z,
    average
  };
};

const getKz = b => {
  const z0 = 8;
  const text = `, так как b ${b < 10 || !b ? ` < 10` : ` ≥ 10`}`;
  return { kz: b < 10 || !b ? 1 : z0 / b + 0.2, kzText: text };
};

const getD1Db = (task, gamma2s) => {
  const d = Math.abs(task.fl - task.dl);
  const dn = Math.abs(task.fl - task.nl);
  let d1,
    db,
    dbText = "",
    d1Text = "";
  if (task.basement) {
    d1 = task.hs + (task.hcf * task.gammacf) / gamma2s;
    db = d - task.hs - task.hcf;
    if (d1 > d) {
      d1 = d;
      db = 0;
    }
    if (db > 2) {
      db = 2;
      dbText = ", так как расчётное значение превышает максимально допустимое.";
    }
  } else {
    d1 = d;
    db = 0;
  }
  if (task.preparation) {
    d1 += task.hp;
    d1Text = " увеличено на толщину подготовки.";
  }
  return { d, d1, db, dn, dbText, d1Text };
};

const getValueByInterpolation = (fx1, fx0, x1, x0, x) => {
  return fx0 + ((fx1 - fx0) / (x1 - x0)) * (x - x0);
};

const getGammaC1GammaC2 = (task, soilUnder) => {
  const data = table54;
  let c1,
    c2,
    index = "",
    c1Text,
    c2Text;
  if (task.well[soilUnder].structure === "loose") {
    c1Text = ", так как в основании рыхлы пески";
    return {
      c1: 1,
      c1Text: c1Text,
      c2: 1,
      c2Text: c1Text
    };
  }
  // gammaC1
  if (task.well[soilUnder].type === "clay") {
    if (task.well[soilUnder].il <= 0.25) {
      c1Text = "Il ≤ 0.25";
      index = "clay25";
      c1 = data[index][0];
    } else if (task.well[soilUnder].il <= 0.5) {
      c1Text = "0.25 < Il ≤ 0.5";
      index = "clay50";
      c1 = data[index][0];
    } else {
      c1Text = "Il > 0.5";
      index = "clay100";
      c1 = data[index][0];
    }
    c1Text = ", так как в основании глины с " + c1Text;
  } else {
    c1 =
      data[task.well[soilUnder].moisture || task.well[soilUnder].structure][0];
    c1Text =
      ", так как в основании пески " +
      (task.well[soilUnder].structure === "same"
        ? "крупнообломочные"
        : task.well[soilUnder].structure === "small"
        ? "мелкие"
        : "пылеватые" +
          (task.well[soilUnder].moisture === "wet"
            ? " влажные, насыщенные водой"
            : " маловлажные"));
  }
  //gammaC2
  if (!task.schema) {
    c2Text = ", так как схема гибкая ";
    c2 = 1;
  } else {
    let n = task.lSize / task.hSize;
    c2Text = ", так как схема жесткая с соотношением L/H = " + n.toFixed(2);
    if (n >= 4 || n <= 1.5) {
      c2 =
        n >= 4
          ? data[index || task.well[soilUnder].structure][1]
          : data[index || task.well[soilUnder].structure][2];
    } else {
      c2Text += ", значение находим интерполяцией";
      c2 = getValueByInterpolation(
        data[index || task.well[soilUnder].structure][1],
        data[index || task.well[soilUnder].structure][2],
        4,
        1.5,
        n
      );
    }
  }
  return {
    c1: c1,
    c1Text: c1Text,
    c2: c2,
    c2Text: c2Text
  };
};

const getGamma2s = task => {
  const d = Math.abs(task.fl - task.nl);
  let gamma2s = 0,
    d1 = 0;
  for (let i = 0; i < task.well.length; i++) {
    if (d1 + task.well[i].thickness < d) {
      d1 += task.well[i].thickness;
      gamma2s += task.well[i].thickness * task.well[i].gamma2;
    } else {
      gamma2s += (d - d1) * task.well[i].gamma2;
      break;
    }
  }
  return gamma2s / d;
};

const getK = task => {
  const text =
    "коэффициент k принят равным " +
    (task.k ? 1.1 : 1) +
    ", так как данные" +
    (task.k
      ? " взяты из таблицы СП"
      : " определены непосрественными испытаниями ");
  return { k: task.k ? 1.1 : 1, kText: text };
};

const getSoilUnder = task => {
  const d = Math.abs(task.fl - task.nl);
  let d1 = 0;
  for (let i = 0; i < task.well.length; i++) {
    d1 += task.well[i].thickness;
    if (d1 > d) return i;
  }
};

const toNumber = data => {
  const task = { ...data };
  for (let i = 0; i < task.well.length; i++) {
    task.well[i].gamma2 = parseFloat(task.well[i].gamma2);
    task.well[i].thickness = parseFloat(task.well[i].thickness);
  }
  Object.keys(task).forEach(key => {
    if (
      key !== "well" &&
      key !== "k" &&
      key !== "schema" &&
      key !== "basement" &&
      key !== "preparation"
    ) {
      task[key] = parseFloat(task[key]);
    }
  });
  return { ...task };
};
