import React from "react";
import classes from "./footer.module.css";

const Footer = props => (
  <footer className={classes.footer}>
    &copy; Благонамеренный инженер 2015 - 2022 |{" "}
    <a href="mailto:alexeybeezduke@gmail.com">Написать</a> |{" "}
    <a
      href="https://money.yandex.ru/to/410011483857938"
      target="_blank"
      rel="noopener noreferrer"
    >
      Поддержать
    </a>{" "}
    |{" "}
    <a
      href="https://www.youtube.com/channel/UCFR-ZsBVdxQO47Lr6LV3aOw"
      target="_blank"
      rel="noopener noreferrer"
    >
      ТыТруба
    </a>{" "}
    |{" "}
    <a
      href="https://vk.com/public177076517"
      target="_blank"
      rel="noopener noreferrer"
    >
      ВКонтакте
    </a>
  </footer>
);

export default Footer;
