import React, { Fragment } from "react";
import MathJax from "react-mathjax";
import classes from "./report.module.css";

import soilsDecoder from "../../util/soilsDecoder";
import { d, dn, d1, db, r } from "../../util/formulas";

const Report = ({ report }) => {
  const { data } = report;
  const { well } = data ? data : "";
  return typeof report === "object" ? (
    <MathJax.Provider>
      <div className={classes.report}>
        <h3 className={classes.reportTitle}>Отчёт</h3>
        <br />В основании {soilsDecoder(well[report.soilUnder].type)}{" "}
        {soilsDecoder(well[report.soilUnder].structure)}{" "}
        {soilsDecoder(well[report.soilUnder].moisture)}
        <br />
        Коэффициенты &gamma;<sub>c1</sub> и &gamma;<sub>c2</sub> подбираются по
        таб.5.4
        <br />
        {report.gammac.c1Text === report.gammac.c2Text ? (
          <Fragment>
            &gamma;<sub>c1</sub> = &gamma;<sub>c2</sub> ={" "}
            {report.gammac.c1.toFixed(2)} {report.gammac.c1Text}
            <br />
            <div className={classes.reportAttention}>
              Расчётное сопротивление оснований R, сложенных рыхлыми песками,
              следует выполнять на основании специальных исследований. Значение
              R, найденное для рыхлых песков следует уточнить по результатам
              испытаний штампа. п.5.6.8
            </div>
          </Fragment>
        ) : (
          <Fragment>
            &gamma;<sub>c1</sub> = {report.gammac.c1.toFixed(2)}{" "}
            {report.gammac.c1Text}
            <br />
            &gamma;<sub>c1</sub> = {report.gammac.c2.toFixed(2)}{" "}
            {report.gammac.c2Text}
            <br />
          </Fragment>
        )}
        k = {report.k.k} {report.k.kText} (п.5.6.7)
        <br />
        Для {report.gamma2.average && "осреднённого"} угла внутреннего трения
        &phi;<sub>2</sub> = {report.gamma2.phi2.toFixed(0)}&deg;
        <br />
        Коэффициенты M подбираются по таб. 5.5
        <br />M<sub>&gamma;</sub> = {report.mmm.Mg}
        <br />M<sub>q</sub> = {report.mmm.Mq}
        <br />M<sub>c</sub> = {report.mmm.Mc}
        <br />k<sub>z</sub> = {report.kz.kz.toFixed(2)} {report.kz.kzText}
        <br />
        &gamma;<sub>II</sub>' = {report.gamma2s.toFixed(2)} т/м<sup>3</sup>{" "}
        осреднённый вес грунтов выше подошвы
        <br />
        &gamma;<sub>II</sub> = {report.gamma2.gamma2.toFixed(2)} т/м<sup>3</sup>{" "}
        {report.gamma2.average && "осреднённый"} вес грунта ниже подошвы
        <br />с<sub>II</sub> = {report.gamma2.c2.toFixed(2)} т/м<sup>2</sup>{" "}
        {report.gamma2.average && "осреднённое"} удельное сцепление грунта ниже
        подошвы
        <br />
        {report.gamma2.average ? (
          data.b < 10 ? (
            <Fragment>
              <div className={classes.reportAttention}>
                Характеристики &phi;<sub>2</sub>, c<sub>2</sub> и &gamma;
                <sub>2</sub> определены как средневзвешенные значения
                характеристик грунтов, залегающих на расстоянии z = b/2 ={" "}
                {(data.b / 2).toFixed(2)} м ниже подошвы фундаменты п.5.6.10
                <br />
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className={classes.reportAttention}>
                Характеристики &phi;<sub>2</sub>, c<sub>2</sub> и &gamma;
                <sub>2</sub> определены как средневзвешенные значения
                характеристик грунтов, залегающих на расстоянии z = z
                <sub>1</sub>
                +0.1b = {4 + 0.1 * data.b.toFixed(2)} м (где z<sub>1</sub> = 4
                м) ниже подошвы фундаменты п.5.6.10
                <br />
              </div>
            </Fragment>
          )
        ) : data.b < 10 ? (
          <Fragment>
            <div className={classes.reportAttention}>
              Характеристики &phi;<sub>2</sub>, c<sub>2</sub> и &gamma;
              <sub>2</sub> соответствуют характеристикам грунта номер{" "}
              {report.soilUnder + 1}, так как на расстоянии z = b/2 ={" "}
              {(data.b / 2).toFixed(2)} м ниже подошвы фундаменты иные грунты не
              встречены, п.5.6.10
              <br />
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className={classes.reportAttention}>
              Характеристики &phi;<sub>2</sub>, c<sub>2</sub> и &gamma;
              <sub>2</sub> соответствуют характеристикам грунта номер{" "}
              {report.soilUnder + 1}, так как на расстояниии z = z<sub>1</sub>
              +0.1b = {4 + 0.1 * data.b.toFixed(2)} м (где z<sub>1</sub> = 4 м)
              ниже подошвы фундаменты иные грунты не встречены, п.5.6.10
              <br />
            </div>
          </Fragment>
        )}
        {report.gamma2.gamma2Text ? (
          <div className={classes.reportAttention}>
            {report.gamma2.gamma2Text}
          </div>
        ) : null}
        Глубина заложения от уровня планировки
        <br />
        <MathJax.Node formula={d(data.fl, data.dl, report.d1db.d)} />
        Глубина заложения от уровня природного рельефа
        <br />
        <MathJax.Node formula={dn(data.fl, data.dl, report.d1db.dn)} />
        {data.basement ? (
          <Fragment>
            Для сооружений с подвалом d<sub>1</sub> определяется по формуле 5.8
            <br />
            <MathJax.Node
              formula={d1(
                data.hs,
                data.hcf,
                data.gammacf,
                report.gamma2s,
                report.d1db.d1,
                data.preparation,
                data.hp,
                report.d1db.d,
                report.d1db.db,
                data.basement
              )}
            />
            Расстояние от уровня планировки до пола подвала
            <br />
            <MathJax.Node
              formula={db(
                report.d1db.d,
                report.d1db.db,
                data.hs,
                data.hcf,
                report.d1db.dbText,
                report.d1db.d1
              )}
            />
            {report.d1db.db === 0 ? (
              <div className={classes.reportAttention}>
                <Fragment>
                  Так как d<sub>1</sub> {">"} d, принято d<sub>1</sub> = d и d
                  <sub>b</sub> = 0 п.5.6.7
                  <br />
                </Fragment>
              </div>
            ) : null}
            {report.d1db.d1Text ? (
              <div className={classes.reportAttention}>
                <Fragment>
                  Значение d<sub>1</sub> {report.d1db.d1Text}
                </Fragment>
              </div>
            ) : null}
            {report.d1db.dbText ? (
              <div className={classes.reportAttention}>
                <Fragment>
                  Значение d<sub>b</sub> принято{report.d1db.dbText}
                </Fragment>
              </div>
            ) : null}
            {parseFloat(data.hs) + parseFloat(data.hcf) < 0.5 ? (
              <Fragment>
                <div className={classes.reportAttention}>
                  Глубина заложения фундамента от пола подвала менее 0.5 п.
                  5.6.9
                </div>
              </Fragment>
            ) : null}
          </Fragment>
        ) : (
          <Fragment>
            <MathJax.Node
              formula={d1(
                data.hs,
                data.hcf,
                data.gammacf,
                report.gamma2s,
                report.d1db.d1,
                data.preparation,
                data.hp,
                report.d1db.d,
                report.d1db.db,
                data.basement
              )}
            />
            Для бесподвальных сооружений d<sub>1</sub> = d, d<sub>b</sub> = 0
            <br />
            {data.preparation ? (
              <div className={classes.reportAttention}>
                <Fragment>
                  Значение d<sub>1</sub> {report.d1db.d1Text}
                </Fragment>
              </div>
            ) : null}
          </Fragment>
        )}
        <div className="reportResult">
          Расчётное сопротивление грунта определяется по формуле 5.7:
          <br />
          <MathJax.Node
            formula={r(
              report.gammac.c1,
              report.gammac.c2,
              report.k.k,
              report.mmm.Mg,
              report.mmm.Mq,
              report.mmm.Mc,
              report.kz.kz,
              data.b,
              report.gamma2.gamma2,
              report.gamma2s,
              report.d1db.d1,
              report.d1db.db,
              report.gamma2.c2,
              report.r
            )}
          />
        </div>
      </div>
    </MathJax.Provider>
  ) : (
    <div>{report}</div>
  );
};

export default Report;
