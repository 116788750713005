const soilsDecoder = (data, cut) => {
  let decoder;
  if (data === "made") {
    decoder = "насыпной грунт";
  } else if (data === "sand") {
    decoder = "песок";
  } else if (data === "clay") {
    decoder = "глина";
  } else if (data === "same") {
    decoder = cut ? "круп." : "крупный или средней крупности";
  } else if (data === "small") {
    decoder = "мелкий";
  } else if (data === "dust") {
    decoder = cut ? "пыл." : "пылеватый";
  } else if (data === "loose") {
    decoder = "рыхлый";
  } else if (data === "semiwet") {
    decoder = cut ? "маловлаж." : "маловлажный";
  } else if (data === "wet") {
    decoder = cut ? "насыщ." : "насыщенный водой";
  } else {
    decoder = data;
  }
  return decoder;
};

export default soilsDecoder;
