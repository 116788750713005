import draft from "./draft";
import randomColor from "./randomColor";
import soilsDecoder from "./soilsDecoder";

const renderDraft = (data, id) => {
  draft.getCoordinates(data);
  const { dataset } = draft;
  const { centerX, centerY, scale, bodyCode } = dataset;
  const { nl, dl, cl, fl } = dataset.levels;
  const { footing, column, height } = dataset.foundation;
  const { offset } = dataset.levelMark;
  const { well } = data;
  const svg = document.querySelector(`#${id}`);
  const defs = document.createElementNS(dataset.svgNS, "defs");
  let heightSVG = svg.height.baseVal.value;
  let widthSVG = svg.width.baseVal.value;
  let line, path, pathBg, temp, g, soilsThickness, title, dimension;

  if (height / scale < 0.3) {
    return "высота фундамента слишком мала";
  }

  soilsThickness = data.well.reduce(
    (total, item) => total + parseFloat(item.thickness) || 0,
    0
  );

  svg.setAttribute(
    "viewBox",
    `0 0 ${widthSVG} ${Math.max(
      (soilsThickness * scale - nl) * 1.4,
      Math.abs(fl) * 1.4,
      heightSVG * 1.4
    )}`
  );

  svg.innerHTML = ""; // cleanup
  // Prepearing defs
  defs.appendChild(draft.trashHatch());
  defs.appendChild(draft.clayHatch());
  defs.appendChild(draft.getObliqueMarker("obliqueV"));
  defs.appendChild(draft.getObliqueMarker("obliqueH"));
  svg.appendChild(defs);

  if (dataset.sandToggle) {
    defs.appendChild(dataset.sandHatch);
  } else {
    dataset.sandHatch = draft.sandHatch();
    defs.appendChild(dataset.sandHatch);
    dataset.sandToggle = true;
  } // Grid
  // Add to svg
  svg.appendChild(
    draft.grid(
      widthSVG * 1.4,
      Math.max(
        (soilsThickness * scale - nl) * 1.4,
        Math.abs(fl) * 1.4,
        heightSVG * 1.4
      )
    )
  );
  svg.appendChild(
    draft.polyMaker({
      title: "Фундамент",
      id: "fBody1",
      class: "fBody",
      points: bodyCode
    })
  );
  // Body
  temp = draft.polyMaker({
    title: "Фундамент",
    id: "fBody2",
    class: "fBody",
    points: bodyCode
  });
  temp.setAttribute("transform", `scale(-1, 1) translate(${-centerX * 2},0)`);
  svg.appendChild(temp);
  // Soils
  dataset.soils = [];
  dataset.soils.push(centerY - nl);
  //basement
  let basementLevel = centerY - nl - fl - data.basement.hs * scale;
  //***
  for (let i = 0; i < well.length; i++) {
    if (!well[i].thickness) {
      continue;
    }
    g = document.createElementNS(draft.dataset.svgNS, "g");
    g.setAttribute("id", `soilGroup${i + 1}`);
    temp = draft.getPath(
      well[i],
      i,
      i === well.length - 1 && well.length > 1,
      basementLevel,
      data.basement.check
    );
    path = draft.pathMaker({ title: ``, id: ``, class: "", d: temp[0] });
    if (well[i].type === "clay") {
      path.setAttribute("fill", "url(#clayHatch)");
    } else {
      path.setAttribute("fill", "url(#sandHatch)");
    }
    let color = randomColor();
    if (dataset.hatchColor[i]) {
      color = dataset.hatchColor[i].color;
    } else {
      dataset.hatchColor.push({ color });
    }

    g.appendChild(path);
    title = `Слой ${i + 1}\n`;
    title += `${soilsDecoder(well[i].type)} ${soilsDecoder(
      well[i].structure
    )} ${soilsDecoder(well[i].moisture)}\n`;
    if (well[i].type !== "made") {
      title += `${well[i].il ? "Iₗ=" + well[i].il + "; " : ""}`;
      //E=${well[i].e} т/м²\n`;
      title += `C₂=${well[i].c2}т/м²; φ₂=${well[i].phi2}°; γ₂=${well[i].gamma2}т/м³`;
    }
    pathBg = draft.pathMaker({
      title: title,
      id: `soil${i}`,
      class: "soils",
      d: temp[0]
    });
    pathBg.setAttribute("fill", color);
    g.appendChild(pathBg);
    if (temp[1]) {
      path = draft.pathMaker({
        title: ``,
        id: ``,
        class: "secondaryLine",
        d: temp[1]
      });
      g.appendChild(path);
    }
    g.setAttribute("fill", color);
    svg.appendChild(g);
  }
  //basement
  if (data.basement.check) {
    line = `${centerX + footing / 2 + 60} ${basementLevel}
    ${centerX + column / 2} ${basementLevel}
    ${centerX + column / 2} ${basementLevel - data.basement.hcf * scale}
    ${centerX + footing / 2 + 60} ${basementLevel - data.basement.hcf * scale}`;
    svg.appendChild(
      draft.polyMaker({
        title: "Пол подвала",
        id: "",
        class: "basement",
        points: line
      })
    );
    line = `${centerX - footing / 2 - 60} ${basementLevel}
    ${centerX - column / 2} ${basementLevel}
    ${centerX - column / 2} ${basementLevel - data.basement.hcf * scale}
    ${centerX - footing / 2 - 60} ${basementLevel - data.basement.hcf * scale}`;
    svg.appendChild(
      draft.polyMaker({
        title: "Пол подвала",
        id: "",
        class: "basement",
        points: line
      })
    );
  }
  // CL
  line = draft.lineMaker({
    class: "dimLine",
    x1: `${centerX + column * 1.1}`,
    y1: `${centerY - cl}`,
    x2: `${centerX + footing + 60}`,
    y2: `${centerY - cl}`
  });
  svg.appendChild(
    draft.levelMark(
      centerX + footing + 45,
      centerY - cl + offset,
      cl / 100,
      "CL",
      false,
      false,
      line
    )
  );
  // DL
  if (dl !== nl) {
    path = draft.pathMaker({
      title: ``,
      id: ``,
      class: "secondaryLine dlLine",
      d: draft.getLinePath(centerY - dl, column)
    });
    svg.appendChild(path);
    line = draft.lineMaker({
      class: "dimLine",
      x1: `${centerX - footing - 60}`,
      y1: `${centerY - dl}`,
      x2: `${centerX - footing + 20}`,
      y2: `${centerY - dl}`
    });
    svg.appendChild(
      draft.levelMark(
        centerX - footing - 45,
        centerY - dl + (dl > nl ? offset : -offset),
        dl / 100,
        "DL",
        dl > nl ? false : true,
        true,
        line
      )
    );
  }
  // NL
  line = draft.lineMaker({
    class: "dimLine",
    x1: `${centerX - footing - 60}`,
    y1: `${centerY - nl}`,
    x2: `${centerX - footing + 20}`,
    y2: `${centerY - nl}`
  });
  svg.appendChild(
    draft.levelMark(
      centerX - footing - 45,
      centerY - nl - (dl > nl ? offset : -offset),
      nl / 100,
      dl !== nl ? "NL" : "DL,NL",
      dl > nl ? true : false,
      true,
      line
    )
  );

  line = draft.lineMaker({
    // FL
    class: "dimLine",
    x1: `${centerX + (footing * 1.1) / 2}`,
    y1: `${centerY - fl}`,
    x2: `${centerX + footing + 60}`,
    y2: `${centerY - fl}`
  });
  svg.appendChild(
    draft.levelMark(
      centerX + footing + 45,
      centerY - fl + offset,
      fl / 100,
      "FL",
      false,
      false,
      line
    )
  );

  if (soilsThickness) {
    line = draft.lineMaker({
      // down of soils
      class: "dimLine",
      x1: `${centerX - footing - 60}`,
      y1: `${centerY - nl + soilsThickness * scale}`,
      x2: `${centerX - footing + 20}`,
      y2: `${centerY - nl + soilsThickness * scale}`
    });
    svg.appendChild(
      draft.levelMark(
        centerX - footing - 45,
        centerY - nl + soilsThickness * scale + offset,
        (nl - soilsThickness * scale) / 100,
        "",
        false,
        true,
        line
      )
    );
  }
  if (data.basement.check) {
    dimension = draft.getVerticalDimension(
      basementLevel,
      centerY - fl,
      centerX + (footing * 1.1) / 2,
      75,
      `hs=${(data.basement.hs * 1000).toFixed(0)}`
    );
    svg.appendChild(dimension);
    dimension = draft.getVerticalDimension(
      basementLevel - data.basement.hcf * scale,
      basementLevel,
      centerX + (footing * 1.1) / 2,
      95,
      `hcf=${(data.basement.hcf * 1000).toFixed(0)}`,
      "",
      45
    );
    svg.appendChild(dimension);
  }

  if (!data.report || typeof data.report === "string") return;
  // thickness z
  line = draft.lineMaker({
    class: "zLine",
    x1: centerX - draft.dataset.limit,
    y1: centerY - fl + data.report.gamma2.z * scale,
    x2: centerX + draft.dataset.limit,
    y2: centerY - fl + data.report.gamma2.z * scale
  });
  svg.appendChild(line);
  dimension = draft.getVerticalDimension(
    centerY - fl,
    centerY - fl + data.report.gamma2.z * scale,
    centerX + (footing * 1.1) / 2,
    75,
    `z=${(data.report.gamma2.z * 1000).toFixed(0)}`
  );
  svg.appendChild(dimension);
  // b
  dimension = draft.getHorizontalDimension(
    centerX - footing / 2,
    centerX + footing / 2,
    centerY - fl + 10,
    50,
    `b=${(data.report.data.b * 1000).toFixed(0)}`
  );
  svg.appendChild(dimension);
  // d
  dimension = draft.getVerticalDimension(
    centerY - dl,
    centerY - fl,
    centerX - footing / 2 - 10,
    60,
    `d=${(data.report.d1db.d * 1000).toFixed(0)}`,
    true
  );
  svg.appendChild(dimension);
  // dn
  dimension = draft.getVerticalDimension(
    centerY - nl,
    centerY - fl,
    centerX - footing / 2 - 10,
    90,
    `dn=${(data.report.d1db.dn * 1000).toFixed(0)}`,
    true
  );
  svg.appendChild(dimension);

  return "";
};

export default renderDraft;
