const draft = {
  dataset: {
    svgNS: "http://www.w3.org/2000/svg",
    scale: 100,
    x: 0,
    y: 0,
    centerX: 225,
    centerY: 150,
    gridStep: 10,
    crossStep: 100,
    crossSize: 7,
    limit: 150,
    levelMark: {
      // x2
      arrowHeight: 8,
      arrowWidth: 10,
      height: 22,
      length: 50,
      textSize: 8,
      offset: -2
    },
    foundation: {
      column: 90,
      footing: 180,
      hStep: 45,
      height: 210
    },
    levels: {
      nl: 0,
      dl: 0,
      fl: -200,
      cl: 10
    },
    sandToggle: false,
    sandHatch: "",
    soils: [],
    hatchColor: []
  },

  getCoordinates(data) {
    const { centerX, centerY, scale } = this.dataset;

    Object.keys(data.levels).forEach(key => {
      if (data.levels[key]) {
        this.dataset.levels[key] = data.levels[key] * scale;
      }
    });

    //if (data.b) this.dataset.foundation.footing = parseFloat(data.b) * scale;

    const { cl, fl } = this.dataset.levels;
    const { column, hStep, footing } = this.dataset.foundation;

    this.dataset.foundation.height = Math.abs(fl) + cl;

    this.dataset.bodyCode =
      `${centerX} ${centerY - cl} ${centerX + column / 2} ${centerY -
        cl} ${centerX + column / 2} ${centerY - fl - hStep} ` +
      `${centerX + footing / 2} ${centerY - fl - hStep} ${centerX +
        footing / 2} ${centerY - fl} ${centerX} ${centerY - fl}`;

    this.dataset.rec1 = {
      up: centerY - cl,
      down: centerY - fl - hStep,
      left: -column / 2 + centerX,
      right: column / 2 + centerX
    };
    this.dataset.rec2 = {
      up: this.dataset.rec1.down,
      down: this.dataset.rec1.down + hStep,
      left: -footing / 2 + centerX,
      right: footing / 2 + centerX
    };
  },

  getPath(data, index, last, basementLevel, basement) {
    let tempPath = "",
      path = "",
      contrPath = "",
      allPath = [];
    const { centerX, limit, rec1, rec2, scale } = this.dataset;
    const h = parseFloat(data.thickness) * scale;
    let up = this.dataset.soils[index];
    const down = up + h;
    this.dataset.soils.push(down);
    //basement
    if (basement) {
      if (up < basementLevel && down > basementLevel) {
        up = basementLevel;
      } else if (up < basementLevel && down < basementLevel) {
        return "";
      }
    }
    //***
    if (
      (down <= rec1.down && up >= rec1.up) ||
      (down <= rec2.down && up >= rec2.up)
    ) {
      //console.log('Two rectangle');
      if (up >= rec2.up) {
        path = `M ${centerX + limit} ${up} ${rec2.right} ${up} ${
          rec2.right
        } ${down} ${centerX + limit} ${down} Z `;
        path += `M ${centerX - limit} ${up} ${rec2.left} ${up} ${
          rec2.left
        } ${down} ${centerX - limit} ${down} Z `;
        if (!last) {
          tempPath = `M ${centerX + limit} ${down} ${rec2.right} ${down} `;
          tempPath += `M ${centerX - limit} ${down} ${rec2.left} ${down} `;
          tempPath +=
            index === 0
              ? `M ${centerX + limit} ${up} ${rec2.right} ${up} M ${centerX -
                  limit} ${up} ${rec2.left} ${up}`
              : ``;
        }
      } else {
        path = `M ${centerX + limit} ${up} ${rec1.right} ${up} ${
          rec1.right
        } ${down} ${centerX + limit} ${down} Z `;
        path += `M ${centerX - limit} ${up} ${rec1.left} ${up} ${
          rec1.left
        } ${down} ${centerX - limit} ${down} Z `;
        if (!last) {
          tempPath = `M ${centerX + limit} ${down} ${rec1.right} ${down} `;
          tempPath += `M ${centerX - limit} ${down} ${rec1.left} ${down} `;
          tempPath +=
            index === 0
              ? `M ${centerX + limit} ${up} ${rec1.right} ${up} M ${centerX -
                  limit} ${up} ${rec1.left} ${up}`
              : ``;
        }
      }
      allPath.push(path);
      allPath.push(tempPath);
    } else if (up >= rec2.down || down <= rec1.up) {
      //console.log('One rectangle');
      path = `M ${centerX + limit} ${up} ${centerX - limit} ${up} ${centerX -
        limit} ${down} ${centerX + limit} ${down} Z`;
      allPath.push(path);
      if (!last) {
        path = `M ${centerX + limit} ${down} ${centerX - limit} ${down} `;
        path +=
          index === 0
            ? `M ${centerX + limit} ${up} ${centerX - limit} ${up}`
            : ``;
        allPath.push(path);
      }
    } else {
      //console.log("Complex shape");
      path = "M";
      contrPath = "M";
      if (up < rec1.up) {
        path += ` ${centerX + limit} ${up} ${centerX} ${up} ${centerX} ${
          rec1.up
        } ${rec1.right} ${rec1.up} `;
        contrPath += ` ${centerX - limit} ${up} ${centerX} ${up} ${centerX} ${
          rec1.up
        } ${rec1.left} ${rec1.up} `;
        if (down > rec1.down) {
          path += `${rec1.right} ${rec1.down} ${rec2.right} ${rec2.up} `;
          contrPath += `${rec1.left} ${rec1.down} ${rec2.left} ${rec2.up} `;
        }
        tempPath =
          index === 0
            ? `M ${centerX + limit} ${up} ${centerX - limit} ${up} `
            : ``;
      } else if (up < rec1.down) {
        path += ` ${centerX + limit} ${up} ${rec1.right} ${up} ${rec1.right} ${
          rec1.down
        } ${rec2.right} ${rec2.up} `;
        contrPath += ` ${centerX - limit} ${up} ${rec1.left} ${up} ${
          rec1.left
        } ${rec1.down} ${rec2.left} ${rec2.up} `;
        tempPath =
          index === 0
            ? `M ${centerX + limit} ${up} ${rec1.right} ${up} M ${centerX -
                limit} ${up} ${rec1.left} ${up} `
            : ``;
      } else if (up >= rec2.up) {
        path += ` ${centerX + limit} ${up} ${rec2.right} ${up} ${rec2.right} ${
          rec2.down
        } `;
        contrPath += ` ${centerX - limit} ${up} ${rec2.left} ${up} ${
          rec2.left
        } ${rec2.down} `;
        tempPath =
          index === 0
            ? `M ${centerX + limit} ${up} ${rec2.right} ${up} M ${centerX -
                limit} ${up} ${rec2.left} ${up} `
            : ``;
      }
      if (down < rec1.down) {
        path += ` ${rec1.right} ${down} ${centerX + limit} ${down} Z`;
        contrPath += ` ${rec1.left} ${down} ${centerX - limit} ${down} Z`;
        tempPath += `M ${rec1.left} ${down} ${centerX - limit} ${down} M ${
          rec1.right
        } ${down} ${centerX + limit} ${down} `;
      } else if (down <= rec2.down) {
        path += ` ${rec2.right} ${down} ${centerX + limit} ${down} Z`;
        contrPath += ` ${rec2.left} ${down} ${centerX - limit} ${down} z`;
        tempPath += `M ${rec2.left} ${down} ${centerX - limit} ${down} M ${
          rec2.right
        } ${down} ${centerX + limit} ${down} `;
      } else {
        path += `${rec2.right} ${rec2.down} ${centerX} ${
          rec2.down
        } ${centerX} ${down} ${centerX + limit} ${down} Z`;
        contrPath += `${rec2.left} ${rec2.down} ${centerX} ${
          rec2.down
        } ${centerX} ${down} ${centerX - limit} ${down} Z`;
        tempPath += `M ${centerX + limit} ${down} ${centerX - limit} ${down} `;
      }
      path += " " + contrPath;
      allPath.push(path);
      allPath.push(tempPath);
    }
    return allPath;
  },

  getLinePath(y, column) {
    const { rec1, rec2, centerX, limit } = this.dataset;
    let path = "";
    if (y < rec1.up || y > rec2.down) {
      path += `M ${centerX - limit} ${y} ${centerX + limit} ${y}`;
    } else if (y >= rec1.up && y < rec1.down) {
      path += `M ${centerX - limit} ${y} ${rec1.left} ${y} M ${centerX +
        limit} ${y} ${rec1.right} ${y}`;
    } else {
      path += `M ${centerX - limit} ${y} ${rec2.left} ${y} M ${centerX +
        limit} ${y} ${rec2.right} ${y}`;
    }
    return path;
  },

  legendText(data) {
    const { x, y, first, second, className } = data;
    const firstText = document.createElementNS(draft.dataset.svgNS, "text");
    const secondText = document.createElementNS(draft.dataset.svgNS, "text");
    const g = document.createElementNS(draft.dataset.svgNS, "g");
    firstText.setAttribute("class", `legendFirstText ${className}`);
    firstText.textContent = first;
    firstText.setAttribute("x", x);
    firstText.setAttribute("y", y);
    secondText.setAttribute("class", `legendSecondText ${className}`);
    secondText.textContent = second;
    secondText.setAttribute("x", x + 10);
    secondText.setAttribute("y", y + 5);
    g.appendChild(firstText);
    g.appendChild(secondText);
    return g;
  },

  tooltip(title) {
    const tooltip = document.createElementNS(this.dataset.svgNS, "title");
    tooltip.textContent = title;
    return tooltip;
  },

  dotMaker(data) {
    const line = document.createElementNS(this.dataset.svgNS, "line");
    const title = this.tooltip(data.title);
    line.setAttribute("class", data.class);
    line.setAttribute("x1", data.x1);
    line.setAttribute("y1", data.y1);
    line.setAttribute("x2", data.x2);
    line.setAttribute("y2", data.y2);
    line.appendChild(title);
    return line;
  },
  lineMaker(data) {
    const line = document.createElementNS(this.dataset.svgNS, "line");
    line.setAttribute("class", data.class);
    line.setAttribute("x1", data.x1);
    line.setAttribute("y1", data.y1);
    line.setAttribute("x2", data.x2);
    line.setAttribute("y2", data.y2);
    return line;
  },
  polyMaker(data) {
    const tooltip = this.tooltip(data.title);
    const poly = document.createElementNS(this.dataset.svgNS, "polyline");
    poly.setAttribute("id", data.id);
    poly.setAttribute("class", data.class);
    poly.setAttribute("points", data.points);
    poly.appendChild(tooltip);
    return poly;
  },
  pathMaker(data) {
    const tooltip = this.tooltip(data.title);
    const path = document.createElementNS(this.dataset.svgNS, "path");
    path.setAttribute("id", data.id);
    path.setAttribute("class", data.class);
    path.setAttribute("d", data.d);
    data.title && path.appendChild(tooltip);
    return path;
  },

  cross(x, y) {
    const path = document.createElementNS(this.dataset.svgNS, "path");
    let d = `M ${x - this.dataset.crossSize} ${y} ${x +
      this.dataset.crossSize} ${y}`;
    d += `M ${x} ${y - this.dataset.crossSize} ${x} ${y +
      this.dataset.crossSize}`;
    path.setAttribute("d", d);
    path.setAttribute("class", "gridCross");
    return path;
  },

  grid(width, height) {
    const { centerX, centerY, gridStep, crossStep } = this.dataset;
    const g = document.createElementNS(this.dataset.svgNS, "g");
    let line, path, className;
    g.setAttribute("id", "grid");
    for (let i = centerX; i < width; i += gridStep) {
      className =
        Math.round(i - centerX) % crossStep === 0
          ? "gridPrimaryLine"
          : "gridSecondaryLine";
      line = this.lineMaker({
        class: className,
        x1: i,
        y1: 0,
        x2: i,
        y2: height
      });
      g.appendChild(line);
      line = this.lineMaker({
        class: className,
        x1: centerX - (i - centerX),
        y1: 0,
        x2: centerX - (i - centerX),
        y2: height
      });
      g.appendChild(line);
    }
    for (let i = centerY; i < height; i += gridStep) {
      className =
        Math.round(i - centerY) % crossStep === 0
          ? "gridPrimaryLine"
          : "gridSecondaryLine";
      line = this.lineMaker({
        class: className,
        x1: 0 - 450 * 0.4,
        y1: i,
        x2: width,
        y2: i
      });
      g.appendChild(line);
      line = this.lineMaker({
        class: className,
        x1: 0 - 450 * 0.4,
        y1: centerY - (i - centerY),
        x2: width,
        y2: centerY - (i - centerY)
      });
      g.appendChild(line);
    }
    for (let i = centerX; i <= width; i += gridStep) {
      for (let k = centerY; k <= height; k += gridStep) {
        if (
          Math.round(i - centerX) % crossStep === 0 &&
          Math.round(k - centerY) % crossStep ===
            0 /*||
          (i === 0 && k % crossStep === 0) || (i % crossStep === 0 && k === 0)*/
        ) {
          path = this.cross(i, k);
          g.appendChild(path);
          path = this.cross(centerX - (i - centerX), k);
          g.appendChild(path);
          path = this.cross(i, centerY - (k - centerY));
          g.appendChild(path);
          path = this.cross(centerX - (i - centerX), centerY - (k - centerY));
          g.appendChild(path);
        }
      }
    }
    return g;
  },

  levelMark(x, y, upNote, downNote, down, left, line) {
    // x,y - down of arrow
    let d;
    const k = down ? -1 : 1;
    const j = left ? -1 : 1;
    const g = document.createElementNS(this.dataset.svgNS, "g");
    const arrowPath = document.createElementNS(this.dataset.svgNS, "path");
    const linePath = document.createElementNS(this.dataset.svgNS, "path");
    const upText = document.createElementNS(this.dataset.svgNS, "text");
    const downText = document.createElementNS(this.dataset.svgNS, "text");
    const {
      arrowWidth,
      arrowHeight,
      height,
      length,
      textSize
    } = this.dataset.levelMark;

    d = `M ${x - arrowWidth} ${y - arrowHeight * k} ${x} ${y} ${x +
      arrowWidth} ${y - arrowHeight * k}`;
    arrowPath.setAttribute("class", "dimArrow");
    arrowPath.setAttribute("d", d);

    d = `M ${x} ${y} L ${x} ${y - height * k} ${x + length * j} ${y -
      height * k}`;
    linePath.setAttribute("class", "dimLine");
    linePath.setAttribute("d", d);

    upText.textContent = parseFloat(upNote) > 0 ? "+" : "";
    upText.textContent += parseFloat(upNote).toFixed(3);
    upText.setAttribute("class", "levelMarkText");
    upText.setAttribute("x", `${left ? x - length : x + 5}`);
    upText.setAttribute("y", `${down ? y + height * 0.8 : y - height * 1.2}`);

    downText.textContent = downNote;
    downText.setAttribute("class", "levelMarkText");
    downText.setAttribute("x", `${left ? x - length : x + 5}`);
    downText.setAttribute("y", `${y - height * k + textSize * 1.7}`);

    g.setAttribute("class", "levelMark");
    line && g.appendChild(line);
    g.appendChild(upText);
    g.appendChild(downText);
    g.appendChild(linePath);
    g.appendChild(arrowPath);
    return g;
  },

  sandHatch() {
    let line;
    const g = document.createElementNS(this.dataset.svgNS, "g");
    const hatch = document.createElementNS(this.dataset.svgNS, "pattern");
    hatch.setAttribute("id", "sandHatch");
    hatch.setAttribute("width", 250);
    hatch.setAttribute("height", 100);
    hatch.setAttribute("patternUnits", "userSpaceOnUse");

    for (let i = 0; i < 1000; i++) {
      let x = Math.floor(Math.random() * 250);
      let y = Math.floor(Math.random() * 100);
      line = document.createElementNS(this.dataset.svgNS, "line");
      line.setAttribute("x1", x);
      line.setAttribute("y1", y);
      line.setAttribute("x2", x + 1);
      line.setAttribute("y2", y + 1);
      g.appendChild(line);
    }

    g.setAttribute("class", "sandHatch");

    hatch.appendChild(g);

    return hatch;
  },

  clayHatch() {
    let line;
    const g = document.createElementNS(this.dataset.svgNS, "g");
    const hatch = document.createElementNS(this.dataset.svgNS, "pattern");
    hatch.setAttribute("id", "clayHatch");
    hatch.setAttribute("width", 20);
    hatch.setAttribute("height", 7);
    hatch.setAttribute("patternUnits", "userSpaceOnUse");

    line = document.createElementNS(this.dataset.svgNS, "line");
    line.setAttribute("x1", 0);
    line.setAttribute("y1", 3);
    line.setAttribute("x2", 20);
    line.setAttribute("y2", 3);
    g.appendChild(line);

    g.setAttribute("class", "clayHatch");

    hatch.appendChild(g);

    return hatch;
  },

  trashHatch() {
    let line;
    const g = document.createElementNS(this.dataset.svgNS, "g");
    const hatch = document.createElementNS(this.dataset.svgNS, "pattern");
    hatch.setAttribute("id", "trashHatch");
    hatch.setAttribute("width", 20);
    hatch.setAttribute("height", 20);
    hatch.setAttribute("patternUnits", "userSpaceOnUse");

    line = document.createElementNS(this.dataset.svgNS, "line");
    line.setAttribute("x1", 1);
    line.setAttribute("y1", 20);
    line.setAttribute("x2", 20);
    line.setAttribute("y2", 1);
    g.appendChild(line);

    line = document.createElementNS(this.dataset.svgNS, "line");
    line.setAttribute("x1", 0);
    line.setAttribute("y1", 1);
    line.setAttribute("x2", 1);
    line.setAttribute("y2", 0);
    g.appendChild(line);
    g.setAttribute("class", "trashHatch");

    hatch.appendChild(g);

    return hatch;
  },

  getObliqueMarker(id) {
    const marker = document.createElementNS(this.dataset.svgNS, "marker");
    marker.setAttribute("class", "dimLine");
    marker.setAttribute("id", `${id}`);
    marker.setAttribute("viewBox", "0 0 10 10");
    marker.setAttribute("refX", "5");
    marker.setAttribute("refY", "5");
    marker.setAttribute("markerWidth", "10");
    marker.setAttribute("markerHeight", "10");
    let line = document.createElementNS(this.dataset.svgNS, "line");
    line.setAttribute("class", "dimLine");
    line.setAttribute("x1", 0);
    line.setAttribute("y1", id === "obliqueV" ? 0 : 10);
    line.setAttribute("x2", 10);
    line.setAttribute("y2", id === "obliqueV" ? 10 : 0);
    marker.appendChild(line);
    line = document.createElementNS(this.dataset.svgNS, "line");
    line.setAttribute("class", "dimLine");
    line.setAttribute("x1", 5);
    line.setAttribute("y1", 0);
    line.setAttribute("x2", 5);
    line.setAttribute("y2", 10);
    marker.appendChild(line);

    return marker;
  },

  getVerticalDimension(yUp, yDown, x, xOffset, dim, mirror, lineOffset) {
    const k = mirror ? -1 : 1;
    const g = document.createElementNS(this.dataset.svgNS, "g");
    const text = document.createElementNS(this.dataset.svgNS, "text");
    let line = document.createElementNS(this.dataset.svgNS, "line");
    line.setAttribute("class", "dimLine");
    line.setAttribute("x1", x);
    line.setAttribute("y1", yUp);
    line.setAttribute("x2", x + (xOffset + 10) * k);
    line.setAttribute("y2", yUp);
    g.appendChild(line);
    line = document.createElementNS(this.dataset.svgNS, "line");
    line.setAttribute("class", "dimLine");
    line.setAttribute("x1", x);
    line.setAttribute("y1", yDown);
    line.setAttribute("x2", x + (xOffset + 10) * k);
    line.setAttribute("y2", yDown);
    g.appendChild(line);
    line = document.createElementNS(this.dataset.svgNS, "line");
    line.setAttribute("class", "dimLine");
    line.setAttribute("x1", x + xOffset * k);
    line.setAttribute("y1", yUp);
    line.setAttribute("x2", x + xOffset * k);
    line.setAttribute("y2", yDown);
    line.setAttribute("marker-start", "url(#obliqueV)");
    line.setAttribute("marker-end", "url(#obliqueV)");
    g.appendChild(line);
    text.textContent = dim;
    text.setAttribute("class", "dimText");
    text.setAttribute("x", x + (xOffset - 5 * k) * k + (lineOffset || 0));
    text.setAttribute("y", yUp + (yDown - yUp) / 2 + 25);
    text.setAttribute(
      "transform",
      `rotate(-90, ${x + (xOffset - 5 * k) * k}, ${yUp +
        (yDown - yUp) / 2 +
        25})`
    );
    g.appendChild(text);

    return g;
  },

  getHorizontalDimension(xLeft, xRight, y, yOffset, dim) {
    const g = document.createElementNS(this.dataset.svgNS, "g");
    const text = document.createElementNS(this.dataset.svgNS, "text");
    let line = document.createElementNS(this.dataset.svgNS, "line");
    line.setAttribute("class", "dimLine");
    line.setAttribute("x1", xLeft);
    line.setAttribute("y1", y);
    line.setAttribute("x2", xLeft);
    line.setAttribute("y2", y + yOffset + 5);
    g.appendChild(line);
    line = document.createElementNS(this.dataset.svgNS, "line");
    line.setAttribute("class", "dimLine");
    line.setAttribute("x1", xRight);
    line.setAttribute("y1", y);
    line.setAttribute("x2", xRight);
    line.setAttribute("y2", y + yOffset + 5);
    g.appendChild(line);
    line = document.createElementNS(this.dataset.svgNS, "line");
    line.setAttribute("class", "dimLine");
    line.setAttribute("x1", xLeft);
    line.setAttribute("y1", y + yOffset);
    line.setAttribute("x2", xRight);
    line.setAttribute("y2", y + yOffset);
    line.setAttribute("marker-start", "url(#obliqueH)");
    line.setAttribute("marker-end", "url(#obliqueH)");
    g.appendChild(line);
    text.textContent = dim;
    text.setAttribute("class", "dimText");
    text.setAttribute("x", xLeft + (xRight - xLeft) / 2 - 20);
    text.setAttribute("y", y + yOffset - 5);
    g.appendChild(text);

    return g;
  }
};

export default draft;
