import React from "react";
import { withRouter } from "react-router-dom";
import classes from "./header.module.css";

const Header = ({ history }) => {
  const goHome = () => {
    history.push("/");
  };
  return (
    <header className={classes.header}>
      <h1 className={classes.title} onClick={goHome}>
        Расчёты
      </h1>
    </header>
  );
};

export default withRouter(Header);
