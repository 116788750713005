export const d = (down, land, d) => {
  return `d=|FL - DL|=|${down}${land > 0 ? "+" : "-"}${Math.abs(land)}|=${d.toFixed(2)}м`
};

export const dn = (down, relief, dn) => {
  return `d_{n}=|FL - NL|=|${down}${relief > 0 ? "+" : "-"}${Math.abs(relief)}|=${dn.toFixed(2)}м \\\\`;
};

export const db = (d, db, hc, hcf, dbText, d1) => {
  if (dbText) {
    return `d_{b}=d-h_{c}-h_{cf}=${d.toFixed(2)}-${hc}-${hcf}=${(d-hc-hcf).toFixed(2)} > 2, d_{b}=2 м`
  } else if (d1 === d && db === 0) {
    return `d_{b}=d-h_{c}-h_{cf}=${d.toFixed(2)}-${hc}-${hcf}=${(d-hc-hcf).toFixed(2)}, d_{1}>d, d_{b}=0`
  } else {
    return `d_{b}=d-h_{c}-h_{cf}=${d.toFixed(2)}-${hc}-${hcf}=${db.toFixed(2)}м`
  }
};

export const d1 = (hs, hcf, gammacf, gamma2s, d1, preparation, hp, d, db, basement) => {
  if (!basement) {
    if (preparation) {
      return `d_{1}=d+h_{p}=${d.toFixed(2)}+${hp}=${d1.toFixed(2)} м`
    } else {
      return `d_{1}=d=${d1.toFixed(2)} м`
    }
  } else if (preparation) {
    return `d_{1}=h_{s}+h_{cf}\\gamma_{cf}/\\gamma_{II}+h_{p}=${hs}+${hcf}\\times${gammacf}/${gamma2s.toFixed(2)}+${hp}=${d1.toFixed(2)} м`;
  } else if (d1 === d && db === 0) {
    return `d_{1}=h_{s}+h_{cf}\\gamma_{cf}/\\gamma_{II}'=${hs}+${hcf}\\times${gammacf}/${gamma2s.toFixed(2)}=${(parseFloat(hs)+hcf*gammacf/gamma2s).toFixed(2)}, d_{1}>d, d_{1}=d=${d.toFixed(2)} м`
  } else {
    return `d_{1}=h_{s}+h_{cf}\\gamma_{cf}/\\gamma_{II}'=${hs}+${hcf}\\times${gammacf}/${gamma2s.toFixed(2)}=${d1.toFixed(2)} м`;
  }
};

export const r = (gammac1, gammac2, k, mg, mq, mc, kz, b, gamma2, gamma2s, d1, db, c2, r) => {
    let formula = '';
    formula += "R=\\frac{\\gamma_{c1}\\gamma_{c2}}{k}\\left[M_{\\gamma}k_{z}b\\gamma_{II}"+
    "+M_{q}d_{1}\\gamma_{II}'+\\left(M_{q}-1\\right)d_{b}\\gamma_{II}'+M_{c}c_{II}\\right]= \\\\ ";
    formula += `=\\frac{${gammac1}\\times${gammac2.toFixed(2)}}{${k}}`;
    formula += `\\times\\left[${mg}\\times${kz}\\times${b.toFixed(2)}\\times${gamma2.toFixed(2)}`;
    formula += `+${mq}\\times${d1.toFixed(2)}\\times${gamma2s.toFixed(2)}+ \\\\`;
    formula += `+\\left(${mq}-1\\right)\\times${db.toFixed(2)}\\times${gamma2s.toFixed(2)}`;
    formula += `+${mc}\\times${c2.toFixed(2)}\\right]=${r.toFixed(2)} т/м^2 \\\\`;
    return formula
  };
