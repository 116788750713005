import React from "react";

import classes from "./menu-item.module.css";

const MenuItem = ({ href, title, img }) => {
  return (
    <div className={classes.menuItem}>
      <div className={classes.imageBox}>
        <img src={img} alt={title} />
      </div>
      <div className={classes.title}>
        <a href={href}>{title}</a>
      </div>
    </div>
  );
};

export default MenuItem;
